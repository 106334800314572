@font-face {
  font-family: Cairo;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/MoGpUcTu_oZLf0bsrG2xFQ.a751c50f0fe252e12013.ttf);
  unicode-range: U+06??,U+200c-200e,U+2010-2011,U+fb50-fdff,U+fe80-fefc
}

@font-face {
  font-family: Cairo;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/iZqLGfCYEEkWEsr6HQRnSQ.814323efac6934cda3c0.ttf);
  unicode-range: U+0100-024f,U+1e??,U+20a0-20ab,U+20ad-20cf,U+2c60-2c7f,U+a720-a7ff
}

@font-face {
  font-family: Cairo;
  font-style: normal;
  font-weight: 400;
  src: url(fonts/gtxIPk0-ZE5IZ2RrdsRLuQ.ded646352e2316d2d379.ttf);
  unicode-range: U+00??,U+0131,U+0152-0153,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2212,U+2215
}

button {
  cursor: pointer;
}

* {
  font-family: "Cairo", sans-serif;
}

.container.rtl {
  direction: rtl;
}

.text-right {
  text-align: right;
}
